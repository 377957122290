import React from 'react';
const SelectBase: React.FC<React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>> = ({ className, ...props }) => {
  return (
    <select
      {...props}
      className={`border-main-500 hover:border-main-300 focus:border-main-100 border-solid border bg-gray-900 outline-none px-2 py-1 placeholder-main-400 ${className}`}
    />
  );
};
export const Select = React.memo(SelectBase);
