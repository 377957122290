import React from 'react';
import { withLayout } from '../../containers/Layout';
import { Router } from '@reach/router';
import { ListSpecies } from '../../containers/Species/ListSpecies';
import { ModalSpecies } from '../../containers/Species/ModalSpecies';
import { NewSpeciesForm } from '../../containers/Species/NewSpeciesForm';

const PageSpeciesBase = () => {
  return (
    <>
      <ListSpecies />
      <NewSpeciesForm />
      <Router>
        <ModalSpecies path="/species/:id" />
      </Router>
    </>
  );
};
const PageSpecies = React.memo(PageSpeciesBase);
export default () => withLayout({ title: 'Species' }, <PageSpecies />);
