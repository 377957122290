import React, { useCallback, useState } from 'react';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import { Spinner } from '../../../components/Spinner';
import { useApi } from '../../../hooks/useApi';
import { TranslationInput } from '../../TranslationInput';
import { Breed } from '@felicetteapp/types';
type NewBreedFormProps = {
  species: string;
};
const NewBreedFormBase: React.FC<NewBreedFormProps> = ({ species }) => {
  const { send, sending, response, error } = useApi<
    | { success: true; error: false; id: string }
    | { success: false; error: true }
  >('admin/breeds', { method: 'post' }, false);
  const [newBreed, setNewBreed] = useState<Omit<Breed, 'id'>>({
    name: '',
    translations: {},
    species: species,
  });

  const handleSubmit = useCallback(() => {
    send({ data: newBreed });
  }, [newBreed, send]);
  return (
    <div className="px-1 py-1">
      <Input
        className="w-full mb-2"
        placeholder="name"
        onChange={(e) => setNewBreed({ ...newBreed, name: e.target.value })}
      />
      <TranslationInput
        value={newBreed.translations}
        onChange={(newTranslations) =>
          setNewBreed({ ...newBreed, translations: newTranslations })
        }
      />
      {error || (response && response.data.error) ? (
        <div className="bg-red-900 px-2 py-2">ops!</div>
      ) : response && response.data.success ? (
        <div className="bg-green-900 px-2 py-2">
          breed {response.data.id} created
        </div>
      ) : null}
      <Button
        className="block w-full bg-green-700 hover:bg-green-900 mt-2"
        onClick={handleSubmit}
      >
        {sending ? <Spinner /> : 'create new breed'}
      </Button>
    </div>
  );
};
export const NewBreedForm = React.memo(NewBreedFormBase);
