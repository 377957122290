import React from 'react';
import { Spinner } from '../../../components/Spinner';
import { useApi } from '../../../hooks/useApi';
import { Species } from '@felicetteapp/types';
import { Button } from '../../../components/Button';
import { navigate } from 'gatsby';

const ListSpeciesBase = () => {
  const { fetching, error, response } = useApi<{
    species: Array<Partial<Species>>;
  }>('admin/species', { method: 'get' });
  return (
    <div className="w-full border border-gray-800 mt-2 mb-2">
      <header className="text-left border-b border-gray-800 py-2 grid sm:grid-cols-4">
        <section className="px-2 py-1">id</section>
        <section className="px-2 py-1">name</section>
        <section className="px-2 py-1">translations</section>
        <section className="px-2 py-1"></section>
      </header>
      <main>
        {fetching ? (
          <div className="mx-2 my-2 text-center">
            <Spinner />
          </div>
        ) : error ? (
          <div className="bg-red-900 px-2 py-2">ops!</div>
        ) : response ? (
          response.data.species.map(({ id, name, translations }) => (
            <div className="py-1 odd:bg-gray-800 bg-opacity-25 grid sm:grid-cols-4 gap-x-2 items-start">
              <section className="px-2 py-1">{id}</section>
              <section className="px-2 py-1">{name}</section>
              <section className="px-2 py-1">
                {translations
                  ? Object.keys(translations)
                      .sort()
                      .map((lang) => (
                        <span className="px-2 odd:bg-gray-800 even:bg-gray-900 inline-block">
                          {lang}
                        </span>
                      ))
                  : null}
              </section>
              <section className="px-2 py-1">
                <Button
                  className="block cursor-pointer hover:bg-gray-700"
                  onClick={() => {
                    navigate(`/species/${id}`);
                  }}
                >
                  view
                </Button>
              </section>
            </div>
          ))
        ) : null}
      </main>
    </div>
  );
};
export const ListSpecies = React.memo(ListSpeciesBase);
