import React, { useState } from 'react';
import { Species } from '@felicetteapp/types';
import { useCallback } from 'react';
import { useApi } from '../../../hooks/useApi';
import { Button } from '../../../components/Button';
import { SpeciesInput } from '../../../components/SpeciesInput';
import { Spinner } from '../../../components/Spinner';
const NewSpeciesFormBase: React.FC<{}> = () => {
  const [newSpecies, setNewSpecies] = useState<Omit<Species, 'id'>>({
    name: '',
    translations: {},
  });

  const { send, sending, response, error } = useApi<{ id: string }>(
    'admin/species',
    { method: 'post' },
    false,
  );
  const handleSubmit = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      send({ data: { ...newSpecies } });
      console.log({ newSpecies });
    },
    [newSpecies, send],
  );
  return (
    <div>
      <h2>New Species</h2>
      <SpeciesInput value={newSpecies} onChange={setNewSpecies} />
      {error ? (
        <div className="px-2 py-2 bg-red-900">ops!</div>
      ) : response ? (
        <div className="px-2 py-2 bg-green-900">
          species id:{response.data.id} created! 🚀
        </div>
      ) : null}
      <Button
        as="button"
        type="submit"
        className="w-full bg-green-700 hover:bg-green-900 mt-2"
        onClick={handleSubmit}
      >
        {sending ? (
          <span>
            <Spinner /> creating...
          </span>
        ) : (
          'create species'
        )}
      </Button>
    </div>
  );
};

export const NewSpeciesForm = React.memo(NewSpeciesFormBase);
