import React from 'react';
import { Container } from '../../containers/Layout/Container';
import { Button } from '../Button';
import { AiOutlineClose } from 'react-icons/ai';
import { useEffect } from 'react';
type ModalProps = {
  children: React.ReactNode;
  header: React.ReactNode;
  bodyPadding?: boolean;
  onClose: () => void;
};
const ModalBase: React.FC<ModalProps> = ({
  children,
  header,
  onClose,
  bodyPadding = true,
}) => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    const previousOverflow = body.style.overflow;
    body.style.overflow = 'hidden';
    return () => {
      body.style.overflow = previousOverflow;
    };
  }, []);
  return (
    <div
      className="w-full h-full max-h-screen overflow-y-auto fixed left-0 top-0 bg-gray-900 bg-opacity-90"
      role="modal"
    >
      <Container>
        <div className="mx-1 my-3 sm:mx-10 sm:my-10 bg-gray-900 h-full border border-gray-800 rounded-lg">
          <header className="border-b border-gray-800 pb-4 px-4 pt-4 flex">
            <section className="flex-1">{header}</section>
            <section>
              <Button
                className="block bg-gray-900 hover:bg-gray-800 rounded-full cursor-pointer px-2 py-2"
                onClick={onClose}
              >
                <AiOutlineClose />
              </Button>
            </section>
          </header>
          <main className={bodyPadding ? 'pb-4 pt-2 px-4' : ''}>
            {children}
          </main>
        </div>
      </Container>
    </div>
  );
};
export const Modal = React.memo(ModalBase);
