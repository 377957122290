import React from 'react';
import { Breed } from '@felicetteapp/types';
import { Button } from '../../../components/Button';
import { navigate } from 'gatsby';
import { NewBreedForm } from '../NewBreedForm';

type ListBreedsProps = {
  breeds: Array<Breed>;
  species: string;
};
const ListBreedsBase: React.FC<ListBreedsProps> = ({ breeds, species }) => {
  return (
    <div className="w-full border border-gray-800">
      <header className="text-left border-b border-gray-800 py-2 grid sm:grid-cols-4">
        <section className="px-2 py-1">id</section>
        <section className="px-2 py-1">name</section>
        <section className="px-2 py-1">translations</section>
        <section className="px-2 py-1"></section>
      </header>
      <main>
        {breeds.map(({ id, name, translations }) => (
          <div className="py-1 odd:bg-gray-800 bg-opacity-25 grid sm:grid-cols-4 gap-x-2 items-start">
            <section className="px-2 py-1 truncate" title={id}>
              {id}
            </section>
            <section className="px-2 py-1">{name}</section>
            <section className="px-2 py-1">
              {translations
                ? Object.keys(translations)
                    .sort()
                    .map((lang) => (
                      <span className="px-2 odd:bg-gray-800 even:bg-gray-900 inline-block">
                        {lang}
                      </span>
                    ))
                : null}
            </section>
            <section className="px-2 py-1">
              <Button
                className="block cursor-pointer hover:bg-gray-700"
                onClick={() => navigate(`/species/${id}`)}
              >
                view
              </Button>
            </section>
          </div>
        ))}
      </main>
      <footer className="px-2 py-2">
        <h3>new breed</h3>
        <NewBreedForm species={species} />
      </footer>
    </div>
  );
};

export const ListBreeds = React.memo(ListBreedsBase);
