import React from 'react';
import { Species } from '@felicetteapp/types';
import { Input } from '../../../components/Input';
import { TranslationInput } from '../../TranslationInput';
type SpeciesLike = Omit<Species, 'id'>;
type EditFormSpeciesProps = {
  value: SpeciesLike;
  onChange: (newValue: SpeciesLike) => void;
};
const EditFormSpeciesBase: React.FC<EditFormSpeciesProps> = ({
  value,
  onChange,
}) => {
  return (
    <>
      <Input
        className="block w-full mb-2"
        placeholder="name"
        value={value.name ? value.name : ''}
        onChange={(e) => onChange({ ...value, name: e.target.value })}
      />
      <TranslationInput
        value={value.translations ? value.translations : {}}
        onChange={(newTranslations) =>
          onChange({ ...value, translations: newTranslations })
        }
      />
    </>
  );
};
export const EditFormSpecies = React.memo(EditFormSpeciesBase);
