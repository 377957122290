import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
export enum AvaliableLanguages {
  en = 'en',
  'pt-BR' = 'pt-BR',
  'es-AR' = 'es-AR',
}

type TranslationValue = {
  [key: string]: string;
};
type TranslationInputProps = {
  value: TranslationValue;
  onChange: (newValue: TranslationValue) => void;
};

const TranslationInputBase: React.FC<TranslationInputProps> = ({
  value,
  onChange,
}) => {
  const [newKey, setNewKey] = useState(AvaliableLanguages.en);
  const [newValue, setNewValue] = useState('');
  return (
    <div className="px-2 py-2 border-solid border-gray-800 border">
      <header className="mb-2">
        <h2>Translations</h2>
      </header>
      <ul>
        {Object.keys(value).map((key) => (
          <li className="grid grid-cols-3 gap-x-2 mt-2" key={`liEl${key}`}>
            <div className="text-right text-gray-600">{key}</div>
            <div>{value[key]}</div>
            <Button
              className="block bg-red-700 hover:bg-red-900 cursor-pointer"
              onClick={() => {
                const newValue = Object.assign({}, value);
                delete newValue[key];
                onChange(newValue);
              }}
            >
              remove
            </Button>
          </li>
        ))}
      </ul>
      <form
        className="grid grid-cols-3 gap-x-2 mt-2"
        onSubmit={(e) => {
          e.preventDefault();
          onChange({ ...value, [newKey]: newValue });
        }}
      >
        <Select
          placeholder="key"
          value={newKey}
          onChange={(e) => setNewKey(e.target.value as AvaliableLanguages)}
          className="block"
        >
          {Object.keys(AvaliableLanguages).map((thisKey) => (
            <option
              key={`optionValEl${thisKey}`}
              value={thisKey}
              disabled={Object.keys(value).indexOf(thisKey) > -1}
            >
              {AvaliableLanguages[thisKey]}
            </option>
          ))}
        </Select>
        <Input
          placeholder="value"
          className="block"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
        <Button
          as="button"
          className="block bg-green-700 hover:bg-green-900 cursor-pointer"
          type="submit"
        >
          add
        </Button>
      </form>
    </div>
  );
};
export const TranslationInput = React.memo(TranslationInputBase);
