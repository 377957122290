import React, { useCallback, useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import { useApi } from '../../../hooks/useApi';
import { Modal } from '../../../components/Modal';
import { Spinner } from '../../../components/Spinner';
import { Species, Breed } from '@felicetteapp/types';
import { Input } from '../../../components/Input';
import { TranslationInput } from '../../TranslationInput';
import { EditFormSpecies } from './EditFormSpecies';
import { Button } from '../../../components/Button';
import { ListBreeds } from '../../Breeds/ListBreeds';

type ModalSpeciesProps = RouteComponentProps<{ id: string }> & {};
const ModalSpeciesBase: React.FC<ModalSpeciesProps> = ({ id }) => {
  const { fetching, response, error } = useApi<{
    species: Species & { breeds: Array<Breed> };
  }>(`admin/species/${id}`, {
    method: 'get',
  });
  const [currentValue, setCurrentValue] = useState<Omit<Species, 'id'>>(null);
  const { sending, response: editResponse, error: editError, send } = useApi<{
    success: boolean;
    error: boolean;
  }>(`admin/species/${id}`, { method: 'put' }, false);
  const handleSubmitEdits = useCallback(() => {
    console.log({ currentValue });
    send({ data: currentValue });
  }, [currentValue, send]);
  useEffect(() => {
    if (response) {
      const { name, translations } = response.data.species;
      setCurrentValue({ name, translations });
    }
  }, [response, setCurrentValue]);
  return (
    <Modal
      header={
        <>
          <h1 className="text-2xl">
            Species {response?.data?.species?.name || id}
          </h1>
        </>
      }
      onClose={() => {
        navigate('/species');
      }}
    >
      {fetching ? (
        <div className="px-2 py-2 text-center">
          <Spinner />
        </div>
      ) : error ? (
        <div className="px-2 py-2 bg-red-900">ops!</div>
      ) : (
        <>
          <div>
            <EditFormSpecies value={currentValue} onChange={setCurrentValue} />
            {editError ? (
              <div className="bg-red-900 px-2 py-2">ops!</div>
            ) : editResponse && editResponse.data.success ? (
              <div className="bg-green-900 px-2 py-2">edited</div>
            ) : null}
            <Button
              className="bg-yellow-700 hover:bg-yellow-900 w-full block cursor-pointer"
              onClick={handleSubmitEdits}
            >
              {sending ? <Spinner /> : 'Edit'}
            </Button>
          </div>
          <div>
            <header className="border-b border-main-800 px-2 py-2">
              <h2 className="text-xl">Breeds</h2>
            </header>
            <main>
              <ListBreeds
                species={response.data.species.id}
                breeds={response.data.species.breeds}
              />
            </main>
          </div>
        </>
      )}
    </Modal>
  );
};
export const ModalSpecies = React.memo(ModalSpeciesBase);
