import React from 'react';
import { Input } from '../Input';
import { Species } from '@felicetteapp/types';
import { TranslationInput } from '../../containers/TranslationInput';
type SpeciesLike = Omit<Species, 'id'>;
type SpeciesInputProps = {
  value: SpeciesLike;
  onChange: (newValue: SpeciesLike) => void;
};
const SpeciesInputBase: React.FC<SpeciesInputProps> = ({ value, onChange }) => {
  return (
    <div className="grid grid-cols-2 gap-x-2 items-start">
      <Input
        placeholder="name"
        value={value.name}
        onChange={(e) => onChange({ ...value, name: e.target.value })}
      />
      <TranslationInput
        value={value.translations}
        onChange={(newTranslations) =>
          onChange({ ...value, translations: newTranslations })
        }
      />
    </div>
  );
};

export const SpeciesInput = React.memo(SpeciesInputBase);
